<template>
  <VModalWidget :dismiss="dismiss" :submit="doSubmit" :submitting="working" :submitTitle="$t('save')">
    <template v-slot:title>{{ $t('title') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-text-field
          append-icon="$vuetify.icons.values.password"
          v-model.trim="form.password"
          :error-messages="passwordErrors"
          v-bind:label="$t('password')"
          type="password"
          required
          @input="$v.form.password.$touch()"
          @blur="$v.form.password.$touch()"
        ></v-text-field>
        <v-text-field
          append-icon="$vuetify.icons.values.password"
          v-model.trim="form.confirmPassword"
          :error-messages="confirmPasswordErrors"
          v-bind:label="$t('confirmPassword')"
          type="password"
          required
          @input="$v.form.confirmPassword.$touch()"
          @blur="$v.form.confirmPassword.$touch()"
        ></v-text-field>
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { mapActions } from 'vuex'
import { required, sameAs } from 'vuelidate/lib/validators'
import { BaseForm, FormErrors, VModalWidget } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'
import { NAMESPACE } from '@argon/iam/users/store'

export default {
  name: 'ModalUserPassword',
  extends: BaseForm,
  mixins: [Modal],
  components: {
    FormErrors,
    VModalWidget
  },
  props: {
    userId: { type: [Number, String], required: true }
  },
  validations: {
    form: {
      password: { required },
      confirmPassword: { required, sameAsPassword: sameAs('password') }
    }
  },
  data: () => ({
    returnPath: { name: 'organizationSettings' },
    form: {
      password: '',
      confirmPassword: ''
    },
    working: false
  }),
  computed: {
    passwordErrors() {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required && errors.push(this.$t('required'))
      this.getServerErrors('password', errors)
      return errors
    },
    confirmPasswordErrors() {
      const errors = []
      if (!this.$v.form.confirmPassword.$dirty) return errors
      !this.$v.form.confirmPassword.required && errors.push(this.$t('required'))
      !this.$v.form.confirmPassword.sameAsPassword && errors.push(this.$t('mismatch'))
      this.getServerErrors('confirm_password', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchAccount']),
    doSubmit() {
      this.resetServerErrors()
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.working = true
      let data = {
        password: this.form.password
      }
      let payload = { userId: this.userId, data }
      this.patchAccount(payload)
        .then((resp) => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.working = false
        })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "confirmPassword": "@:labels.confirmPassword",
    "invalid": "@:labels.invalid",
    "password": "@:labels.password",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "title": "Password"
  },
  "fr": {
    "confirmPassword": "@:labels.confirmPassword",
    "invalid": "@:labels.invalid",
    "password": "@:labels.password",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "title": "Mot de passe"
  }
}
</i18n>
